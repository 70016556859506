import { Component, Inject, PLATFORM_ID, ViewContainerRef } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { ApiServiceService } from './services/api.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ShareService } from './services/share.service';
import { BrowserService } from './services/browser.service';
import { CommonfunctionService } from './services/commonFunctionService';
import { WINDOW } from './window.providers';
import { Meta } from '@angular/platform-browser';
import { Subject, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NgxSmartBannerPlatform, NgxSmartBannerService } from '@netcreaties/ngx-smart-banner';

export let IsPageReloading = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  pageReloading: boolean = false;
  isServer: boolean;
  isBrowser: boolean;
  mobile: boolean;
  url: string;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    public translate: TranslateService,
    private meata: Meta,
    @Inject(WINDOW) private window: Window,
    private fnc: CommonfunctionService,
    private browser: BrowserService,
    private share: ShareService,
    private router: Router,
    private api: ApiServiceService,
    private lang: LocalizeRouterService,
    private readonly ngxSmartBannerService: NgxSmartBannerService,
    private readonly viewContainerRef: ViewContainerRef,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    // Initialize the Smart Banner
    this.ngxSmartBannerService.initialize({
      viewContainerRef: this.viewContainerRef,
      title: 'Estater',
      author: 'Estater',
      price: 'FREE',
      priceSuffix: {
        ios: '- On the App Store',
        android: '- In Google Play',
      },
      icon: {
        ios: 'assets/icons/app-store.png',
        android: 'assets/icons/app.png',
      },
      closeLabel: 'Close',
      buttonLabel: 'Install',
      buttonUrl: {
        ios: 'https://apps.apple.com/us/app/estater/id1535595332',
        android: 'https://play.google.com/store/apps/details?id=com.estater.em&hl=en_IN',
      },
      enabledPlatforms: [NgxSmartBannerPlatform.IOS, NgxSmartBannerPlatform.Android], 

      daysHidden: 0,
      daysReminder: 1,
      rating: {
        ios: 5,
        android: 5,
      },
      hideRating: true,
    });

    this.getHostname();
    this.mobile = this.api.isMobile;
    let path = this.browser.getPathName();
    let preference_id = this.fnc.getParameterByName('preference');

    if (preference_id) {
      let pid = this.fnc.getParameterByName('pid');
      let valutiondata = { pid: pid };
      this.share.setPreference(valutiondata, false, false, preference_id);
    }

    let url = path ? path.split('/')[1] : null;
    if (!this.mobile && !url?.includes('dashboard')) {
      let url: string = this.router.url;

      if (url.includes('privacy-policy/en') || url.includes('privacy-policy/ar')) {
        if (url.includes('privacy-policy/en')) {
          this.browser.setLocalValue('LOCALIZE_DEFAULT_LANGUAGE', 'en');
          this.translate.use('en');
          this.api.changeLanguage('en');
          this.api.setdefaultlang('en');
        } else {
          this.browser.setLocalValue('LOCALIZE_DEFAULT_LANGUAGE', 'ar');
          this.translate.use('ar');
          this.api.changeLanguage('ar');
          this.api.setdefaultlang('ar');
        }
      } else {
        if (url && (url.includes('-ar') || url === 'ar')) {
          this.lang.settings.alwaysSetPrefix = false;
          this.api.changeLanguage('ar');
          this.api.setdefaultlang('ar');
        } else {
          this.lang.settings.alwaysSetPrefix = false;
          this.api.changeLanguage('en');
          this.api.setdefaultlang('en');
        }
      }
    }

    // Listen for route changes
    router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
      let arr_url = [];

      if (event instanceof NavigationStart) {
        this.share.isValuationModalOpen.next(false);
        this.pageReloading = !router.navigated;
        IsPageReloading = true;

        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0, 0);
        }

        arr_url = event.url.split('/');
        if (this.mobile && event.url.includes('dashboard')) {
          return;
        }

        let url: string = this.router.url;
        if (!url.includes('privacy-policy/en') && !url.includes('privacy-policy/ar')) {
          if (arr_url[1].includes('-ar') || arr_url[1] === 'ar') {
            this.api.changeLanguage('ar');
            this.api.setdefaultlang('ar');
          } else {
            this.api.changeLanguage('en');
            this.api.setdefaultlang('en');
          }
        }
      }
    });

    this.isServer = isPlatformServer(this.platformId);
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  getHostname(): void {
    if (!this.window.location.hostname.includes('estater.com')) {
      this.meata.updateTag({ name: 'robots', content: 'noindex' });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
