import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { PLATFORM_ID } from '@angular/core';
import { Injectable, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

declare const google: any;

@Injectable()
export class BrowserService {
  public secureLs: any;
  // selectedLang: string = '';
  location: any;
  public introStage = new BehaviorSubject<any>(this.getCookie('introStage'));
  setUserLocationData: any;
  constructor(@Inject(PLATFORM_ID) private platformId: any, translate: TranslateService,
    private http: HttpClient) {
  //  if (isPlatformBrowser(this.platformId)) {
    //  this.secureLs = new SecureLS({
      //  encodingType: 'aes',
        //isCompression: false
      //});
    //}
    //device check
    // this.selectedLang = isPlatformBrowser(this.platformId)
    //     ? translateCacheService.getCachedLanguage() || translate.getBrowserLang() || 'en'
    //     : 'en';
    if (this.getCookie('introStage') == '1') {

    } else {
      this.setCookie('introStage', 0, 30);
      this.introStage.next(0);
    }
    if (isPlatformBrowser(this.platformId)) {
      this.location = window.location;
    }
    if (isPlatformBrowser(this.platformId)) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            let latlon = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              user_country: '',
              city: '',
            };
            if (this.getCookie('location') && typeof this.getCookie('location') == 'string') {
              this.setUserLocationData = JSON.parse(this.getCookie('location'))
            }
            if (!this.setUserLocationData) {
              this.getAddressByLatlon(
                latlon.latitude,
                latlon.longitude
              ).subscribe({
                next: (res: any) => {
                  if (res.status) {
                    let results = res.results;
                    if (results[0]) {
                      var arrAddress = results;
                      let fullAddr: any = arrAddress[0].address_components;
                      fullAddr.map(element => {
                        if (element.types.indexOf('neighborhood') > -1) {
                          latlon['neighborhood'] = element.long_name;
                        } else if (element.types.indexOf('locality') > -1) {
                          latlon['city'] = element.long_name;
                        } else if (element.types.indexOf('country') > -1) {
                          latlon['user_country'] = element.long_name;
                        } else if (element.types.indexOf('postal_code') > -1) {
                          latlon['postal_code'] = element.long_name;
                        }
                      })
                      this.setUserLocationData = latlon;
                      localStorage.setItem('latlon', JSON.stringify(latlon))
                      this.setCookie('location', JSON.stringify(this.setUserLocationData), 1)
                    } else {
                      this.setUserLocationData = latlon;
                      localStorage.setItem('latlon', JSON.stringify(latlon))
                    }
                  } else {
                    this.setUserLocationData = latlon;
                    localStorage.setItem('latlon', JSON.stringify(latlon))

                  }
                },
                error: () => {
                  this.setUserLocationData = latlon;
                  localStorage.setItem('latlon', JSON.stringify(latlon))
                }
              });
            }
          },
          function (error) {

          },
          { enableHighAccuracy: true, timeout: 30000 }
        );
      }
    }
  }
  getAddressByLatlon(lat, lon) {
    return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=AIzaSyAmnH9DzaMJXdNw2TzgZ18RydRBDIhWHUo`)
  }
  // get and set and remove value on localstorage
  getLocalValue(key: string, isSecure?: boolean): any {
    if (isPlatformBrowser(this.platformId)) {
      if (isSecure) {
        return this.secureLs.get(key);
      } else {
        return localStorage.getItem(key)
      }

    }
  }
  setLocalValue(key: string, value: any, isSecure?: boolean) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(key, value);
      if (isSecure) {
        this.secureLs.set(key, value);
      }
    }
  }

  removeLocalValue(key: any) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(key);
    }
  }

  setSessionStorage(key: string, value: any,) {
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.setItem(key, value);
    }
  }
  getSessionStorage(key: string) {
    if (isPlatformBrowser(this.platformId)) {
      return sessionStorage.getItem(key);
    }
  }
  clearSession() {
    sessionStorage.clear();
  }

  //set and get cookie 
  setCookie(cname: any, cvalue: any, exdays: any) {
    if (isPlatformBrowser(this.platformId)) {
      var d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
  }
  getCookie(cname: any) {
    if (isPlatformBrowser(this.platformId)) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
    }
    return "";
  }

  //get Elements By Tag Name
  getElements(name: string, key: string) {
    let htmlele;
    if (isPlatformBrowser(this.platformId)) {
      switch (name) {
        case 'ByTagName':
          htmlele = document.getElementsByTagName(key);
          break;
        case 'ById':
          htmlele = document.getElementById(key);
          break;
        case 'ByClassName':
          htmlele = document.getElementsByClassName(key);
          break;
      }
    }
    return htmlele;
  }

  getWindowHistory() {
    if (isPlatformBrowser(this.platformId)) {
      window.history.back();
    }
  }
  reload() {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        window.location.reload();
      }, 200);
    }
  }
  getPathName() {
    if (isPlatformBrowser(this.platformId)) {
      return window.location.pathname;
    }
  }
  openNewWindow(currentUrl) {
    if (isPlatformBrowser(this.platformId)) {
      window.open(currentUrl, '_blank');
    }
  }
  openWindow(currentUrl) {
    if (isPlatformBrowser(this.platformId)) {
      window.open(currentUrl, '_self');
    }
  }
}
